/* This module is expected to be imported render-blocking, and as such is not automatically included from `index.ts`.
 */
customElements.define("loading-spinner", class LoadingSpinner extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
    <style type="text/css">
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      loading-spinner {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        height: 100vh;
        justify-content: center;
        width: 100vw;
        opacity: 0.38;
      }
      loading-spinner > div {
        animation: spin 1.6s linear infinite;
        border-color: black transparent black transparent;
        border-radius: 50%;
        border-style: solid;
        border-width: 3px;
        height: 128px;
        width: 128px;
      }
      loading-spinner > i {
        margin-top: 40px;
      }
    </style>
    <div></div><i>Loading..</i>
    `;
  }
});
